import { connect } from "react-redux";
import {
  retryGenericExportRequest,
  storeGenericExportHistorySearchResults,
  searchGenericExportHistory,
} from "../../Store/mutations";
import { useState } from "react";
import moment from "moment";
import "../Exports.css";

const ExportHistoryTableRow = ({
  exportHistoryData,
  retryGenericExportRequest,
  refreshExportHistory,
}) => {
  const {
    id,
    exportType,
    sourceId,
    destinationType,
    label,
    created,
    completed,
    status,
    requestedBy,
  } = exportHistoryData;

  var eventIds = exportHistoryData.items.map((item) => {
    if (item.exportItemType === "EventStream") return item.exportItemId;
    else return null;
  });
  var streamCount = eventIds.filter(Boolean).length;
  var eventCount = [...new Set(eventIds)].filter(Boolean).length;

  var daysSinceCreated = Math.ceil(
    (Date.now() - Date.parse(created)) / 1000 / 60 / 60 / 24
  );
  const [clicked, setClicked] = useState(false);

  const retryEligible = () => {
    var validAge = daysSinceCreated <= 90;
    var validStatus = status != null && status.includes("Error");
    return validStatus && validAge && !clicked;
  };

  const retryExport = () => {
    if (!clicked) {
      retryGenericExportRequest(id);
    }
    setClicked(true);
    setTimeout(() => {
      refreshExportHistory();
    }, 1000);
  };

  return (
    <tr>
      <td>{id}</td>
      <td>{label}</td>
      <td>{sourceId}</td>
      <td>{exportType}</td>
      <td>{eventCount}</td>
      <td>{streamCount}</td>
      <td>{destinationType}</td>
      <td>{moment.utc(created).local().format("yyyy-MM-DD HH:mm:ss")}</td>
      <td>
        {completed === "0001-01-01T00:00:00"
          ? ""
          : moment.utc(completed).local().format("yyyy-MM-DD HH:mm:ss")}
      </td>
      <td id="statusTd">
        <div id="status">
          <div>{status}</div>
          {retryEligible() && (
            <span title="Retry" id="rowRefreshButton">
              <svg
                version="1.1"
                viewBox="0.0 0.0 100.0 100.0"
                fill="none"
                stroke="none"
                strokeLinecap="square"
                strokeMiterlimit="10"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => retryExport()}
                height="30px"
                width="30px"
                textDecoration={"retry"}
              >
                <clipPath id="p.0">
                  <path
                    d="m0 0l100.0 0l0 100.0l-100.0 0l0 -100.0z"
                    clipRule="nonzero"
                  />
                </clipPath>
                <g clipPath="url(#p.0)">
                  <path
                    fill="#000000"
                    fillOpacity="0.0"
                    d="m0 0l100.0 0l0 100.0l-100.0 0z"
                    fillRule="evenodd"
                  />
                  <path
                    fill="#000000"
                    d="m13.497916 46.029488l0 -5.1348457l0 0c0 -14.924736 12.098887 -27.023623 27.02362 -27.023623l0 0l0 -3.873026l9.007874 9.007874l-9.007874 9.007874l0 -3.873024l0 0l0 0c-9.252937 0 -16.753925 7.500988 -16.753925 16.753925l0 5.1348457z"
                    fillRule="evenodd"
                  />
                  <path
                    stroke="#000000"
                    strokeWidth="1.0"
                    strokeLinejoin="round"
                    strokeLinecap="butt"
                    d="m13.497916 46.029488l0 -5.1348457l0 0c0 -14.924736 12.098887 -27.023623 27.02362 -27.023623l0 0l0 -3.873026l9.007874 9.007874l-9.007874 9.007874l0 -3.873024l0 0l0 0c-9.252937 0 -16.753925 7.500988 -16.753925 16.753925l0 5.1348457z"
                    fillRule="evenodd"
                  />
                  <path
                    fill="#000000"
                    d="m86.61942 54.707115l0 5.1348495l0 0c0 14.924736 -12.098885 27.02362 -27.02362 27.02362l0 0l0 3.873024l-9.007877 -9.007874l9.007877 -9.007874l0 3.8730316l0 0l0 0c9.252937 0 16.753922 -7.500992 16.753922 -16.75393l0 -5.1348495z"
                    fillRule="evenodd"
                  />
                  <path
                    stroke="#000000"
                    strokeWidth="1.0"
                    strokeLinejoin="round"
                    strokeLinecap="butt"
                    d="m86.61942 54.707115l0 5.1348495l0 0c0 14.924736 -12.098885 27.02362 -27.02362 27.02362l0 0l0 3.873024l-9.007877 -9.007874l9.007877 -9.007874l0 3.8730316l0 0l0 0c9.252937 0 16.753922 -7.500992 16.753922 -16.75393l0 -5.1348495z"
                    fillRule="evenodd"
                  />
                  <path
                    fill="#000000"
                    d="m53.883743 13.380269l5.1348495 0l0 0c14.924736 0 27.02362 12.098887 27.02362 27.023624l0 0l3.873024 0l-9.007874 9.007874l-9.007874 -9.007874l3.873024 0l0 0l0 0c0 -9.252939 -7.500984 -16.753927 -16.753922 -16.753927l-5.1348495 0z"
                    fillRule="evenodd"
                  />
                  <path
                    stroke="#000000"
                    strokeWidth="1.0"
                    strokeLinejoin="round"
                    strokeLinecap="butt"
                    d="m53.883743 13.380269l5.1348495 0l0 0c14.924736 0 27.02362 12.098887 27.02362 27.023624l0 0l3.873024 0l-9.007874 9.007874l-9.007874 -9.007874l3.873024 0l0 0l0 0c0 -9.252939 -7.500984 -16.753927 -16.753922 -16.753927l-5.1348495 0z"
                    fillRule="evenodd"
                  />
                  <path
                    fill="#000000"
                    d="m46.14698 86.73738l-5.1348457 0l0 0c-14.924736 0 -27.023624 -12.098892 -27.023624 -27.023624l0 0l-3.873026 0l9.0078745 -9.007874l9.007874 9.007874l-3.873026 0l0 0l0 0c0 9.2529335 7.500988 16.753925 16.753927 16.753925l5.1348457 0z"
                    fillRule="evenodd"
                  />
                  <path
                    stroke="#000000"
                    strokeWidth="1.0"
                    strokeLinejoin="round"
                    strokeLinecap="butt"
                    d="m46.14698 86.73738l-5.1348457 0l0 0c-14.924736 0 -27.023624 -12.098892 -27.023624 -27.023624l0 0l-3.873026 0l9.0078745 -9.007874l9.007874 9.007874l-3.873026 0l0 0l0 0c0 9.2529335 7.500988 16.753925 16.753927 16.753925l5.1348457 0z"
                    fillRule="evenodd"
                  />
                </g>
              </svg>
            </span>
          )}
        </div>
      </td>
      <td>{requestedBy}</td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
  retryGenericExportRequest: (id) => dispatch(retryGenericExportRequest(id)),
  refreshExportHistory: () => {
    dispatch(storeGenericExportHistorySearchResults([]));
    dispatch(searchGenericExportHistory());
  },
});

const ConnectedExportHistoryTableRow = connect(
  null,
  mapDispatchToProps
)(ExportHistoryTableRow);

export default ConnectedExportHistoryTableRow;
